
<div class="pdf-wrapper">

  <div class="pdf-toolbar d-flex" *ngIf="pdfSrc">
    <span class="my-icon"
          [inlineSVG]="'/assets/icon/baseline-rotate_left-24px.svg'"
          (click)="rotate(-90)"
          title="Rotate Left"></span>
    <span class="my-icon"
          [inlineSVG]="'/assets/icon/baseline-rotate_right-24px.svg'"
          (click)="rotate(90)"
          title="Rotate Right"></span>
    <span class="my-icon"
          [inlineSVG]="'/assets/icon/baseline-zoom_in-24px.svg'"
          (click)="incrementZoom(0.1)"
          title="Zoom In"></span>
    <span class="my-icon"
          [inlineSVG]="'/assets/icon/baseline-zoom_out-24px.svg'"
          (click)="incrementZoom(-0.1)"
          title="Zoom Out"></span>  <!--(click)="fitToPage = !fitToPage" (click)="showAll = !showAll"-->
    <span class="my-icon"
          [ngClass]="{ active: fitToPage }"
          (click)="toggleFitToPage()"
          [inlineSVG]="'/assets/icon/baseline-insert_drive_file-24px.svg'"
          title="Toggle Fit Page"></span>
    <span class="my-icon"
          [ngClass]="{ active: showAll }"
          (click)="toggleShowAll()"
          [inlineSVG]="'/assets/icon/baseline-multi-pages-24px.svg'"
          title="Toggle Show All"></span>
    
    <ng-container *ngIf="!showAll">
      <span class="my-icon"
            [inlineSVG]="'/assets/icon/baseline-navigate_before-24px.svg'"
            (click)="incrementPage(-1)"
            title="Previous Page"></span>
      <span class="my-icon d-flex">
        <input type="number"
               class="page-num form-control form-control-sm"
               placeholder="Page"
               [(ngModel)]="page"
               pattern="-?[0-9]*(\.[0-9]+)?" />
        <span class="pt-2 pb-2" style="width:38px" *ngIf="pdf">of {{ pdf.numPages }}</span>
      </span>
      <span class="my-icon"
            [inlineSVG]="'/assets/icon/baseline-navigate_next-24px.svg'"
            (click)="incrementPage(1)"
            title="Next Page"></span>
    </ng-container>

    <span class="my-icon"
          [inlineSVG]="'/assets/icon/baseline-search-24px.svg'"
          (click)="showSearch = !showSearch"
          title="Search.."></span>
    <input #queryInp *ngIf="showSearch" class="form-control form-control-sm"
           type="text"
           id="searchbox"
           name="searchbox"
           placeholder="Search..."
           [value]="pdfQuery"
           (input)="searchQueryChanged($event.target.value)"
           (keyup.enter)="searchQueryChanged(queryInp.value)" autocomplete="off" />
  </div>

  <pdf-viewer [show-borders]="true" [src]="pdfSrc"
              [render-text]="true"
              [(page)]="page"
              [rotation]="rotation"
              [original-size]="originalSize"
              [fit-to-page]="fitToPage"
              (after-load-complete)="afterLoadComplete($event)"
              [zoom]="zoom"
              [show-all]="showAll"
              [stick-to-page]="stickToPage"
              [render-text]="renderText"
              [external-link-target]="'blank'"
              [autoresize]="autoresize"
              (error)="onError($event)"
              (on-progress)="onProgress($event)"
              (page-rendered)="pageRendered($event)"></pdf-viewer>
</div>


