import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { P404Component } from './error/404.component';
import { HomeComponent } from './home/home.component';
import { SupplementContractViewerComponent } from './supplement-contract-viewer/supplement-contract-viewer.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'pdfview',
    component: SupplementContractViewerComponent,
  },
  { path: '**', component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
