<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="clearfix">
          <h5 class="float-left mr-4">Welcome To PDF Viewer</h5>
        </div>        
      </div>
    </div>
  </div>
</div>
