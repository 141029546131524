import { Component, ViewChild, Input } from '@angular/core';
import { PDFProgressData, PdfViewerComponent, PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-pdf-doc-viewer',
  templateUrl: './pdf-doc-viewer.component.html',
  styleUrls: ['./pdf-doc-viewer.component.css'],
})
export class PdfDocViewerComponent {

  @Input() pdfSrc: any;

  error: any;
  page = 1;
  rotation = 0;
  zoom = 1.0;
  originalSize = true;
  pdf: any;
  renderText = true;
  progressData: PDFProgressData;
  isLoaded = false;
  stickToPage = false;
  showAll = true;
  autoresize = true;
  fitToPage = false;
  outline: any[];
  isOutlineShown = false;
  showSearch = false;
  pdfQuery = "";

  @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;

  constructor() { }

  toggleShowAll = () => {
    this.showAll = !this.showAll;
    this.fitToPage = !this.showAll;
  }

  toggleFitToPage = () => {
    this.fitToPage = !this.fitToPage;
    this.showAll = !this.fitToPage;
  }

  toggleOutline = () => {
    this.isOutlineShown = !this.isOutlineShown;
  }

  incrementPage=(amount: number) =>  {
    this.page += amount;
  }

incrementZoom=(amount: number)  => {
    this.zoom += amount;
    console.log(this.zoom);
  }

  rotate = (angle: number) => {
    this.rotation += angle;
  }

  /**
   * Render PDF preview on selecting file
   */
  onFileSelected = () => {
    const $pdf: any = document.querySelector("#file");

    if (typeof FileReader !== "undefined") {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.pdfSrc = e.target.result;
      };

      reader.readAsArrayBuffer($pdf.files[0]);
    }
  }

  /**
   * Get pdf information after it's loaded
   * @param pdf
   */
  afterLoadComplete = (pdf: PDFDocumentProxy) =>  {
    this.pdf = pdf;
    this.isLoaded = true;

    this.loadOutline();
  }

  /**
   * Get outline
   */
  loadOutline = () => {
    this.pdf.getOutline().then((outline: any[]) => {
      this.outline = outline;
    });
  }

  /**
   * Handle error callback
   *
   * @param error
   */
  onError = (error: any) =>  {
    this.error = error; // set error

    if (error.name === "PasswordException") {
      const password = prompt(
        "This document is password protected. Enter the password:"
      );

      if (password) {
        this.error = null;
        this.setPassword(password);
      }
    }
  }

  setPassword = (password: string) =>  {
    let newSrc;
    if (this.pdfSrc instanceof ArrayBuffer) {
      newSrc = { data: this.pdfSrc };
    } else if (typeof this.pdfSrc === "string") {
      newSrc = { url: this.pdfSrc };
    } else {
      newSrc = { ...this.pdfSrc };
    }
    newSrc.password = password;
    this.pdfSrc = newSrc;
  }

  /**
   * Pdf loading progress callback
   * @param {PDFProgressData} progressData
   */
  onProgress = (progressData: PDFProgressData) =>  {
    //console.log(progressData);
    this.progressData = progressData;
    this.isLoaded = false;
    this.error = null; // clear error
  }

  getInt = (value: number): number => {
    return Math.round(value);
  }

  /**
   * Navigate to destination
   * @param destination
   */
  navigateTo = (destination: any) =>  {
    this.pdfComponent.pdfLinkService.goToDestination(destination);
    
  }

  /**
   * Scroll view
   */
  scrollToPage = () => {
    this.pdfComponent.pdfViewer.scrollPageIntoView({
      pageNumber: 3,
    });
  }

  /**
   * Page rendered callback, which is called when a page is rendered (called multiple times)
   *
   * @param {CustomEvent} e
   */
  pageRendered=(e: CustomEvent) =>  {
    // console.log("(page-rendered)", e);
  }

  searchQueryChanged = (newQuery: string) =>  {
    if (newQuery !== this.pdfQuery) {
      this.pdfQuery = newQuery;
      this.pdfComponent.eventBus.dispatch("find", {
        query: this.pdfQuery,
        highlightAll: true,
      });
    } else {
      this.pdfComponent.eventBus.dispatch("findagain", {
        query: this.pdfQuery,
        highlightAll: true,
      });
    }
  }
}

