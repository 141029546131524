import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DownloadedFile } from '../models/downloaded-file';
import { PdfService } from '../service/pdf-service';
import { Title } from '@angular/platform-browser';
import { NavbarService } from '../service/navbar-service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';

@Component({
  selector: 'app-supplement-contract-viewer',
  templateUrl: './supplement-contract-viewer.component.html',
  styleUrls: ['./supplement-contract-viewer.component.css'],
})
export class SupplementContractViewerComponent {

  downloadedFile: DownloadedFile = null;
  errorMessage = '';
  encodedPdfUrl = '';

  getUrlParamAndTriggerDownload$ = this.activatedRoute.queryParams
    .pipe(
      tap(params => {
        if (!params.url) {
          throw new HttpErrorResponse({ status: 406 });
        }
        this.encodedPdfUrl = params.url
      }),
      switchMap(() => {
        return this.downloadPdf$(this.encodedPdfUrl)
      }
      ),
      catchError((errResponse: HttpErrorResponse) => {
        this.setErrorMessageAndClearDownloadFile(errResponse.status);
        return of(false)
      }),
    );

  downloadPdf$ = (url: string) => this.pdfService.downloadPDF(url)
    .pipe(
      tap((responseFile: DownloadedFile) => {
        if (responseFile)
          this.setDownloadedFile(responseFile);
      }),
      catchError((errResponse: HttpErrorResponse) => {
        this.setErrorMessageAndClearDownloadFile(errResponse.status);
        return of(null)
      })
    )

  constructor(
    private activatedRoute: ActivatedRoute,
    private pdfService: PdfService,
    private titleService: Title,
    private navbarService: NavbarService
  ) { }

  private setDownloadedFile(file: DownloadedFile) {
    this.downloadedFile = file;
    this.titleService.setTitle(this.downloadedFile.fileName.substring(0, this.downloadedFile.fileName.length - 4));
    this.navbarService.DownloadedFile = this.downloadedFile;
  }

  private setErrorMessageAndClearDownloadFile(httpStatus: number) {
    switch (httpStatus) {
      case 404:
        this.errorMessage = 'Looks like the file that you are looking for is not there!!.';
        break;
      case 401:
      case 403:
        this.errorMessage =
          'This shared file or folder link has been removed or is unavailable to you';
        break;
      case 406:
        this.errorMessage = 'Looks like you arrive to this page without a link to a file.'
        break;
      default:
        this.errorMessage = 'Something went wrong, contact an admin.'
        break;
    }

    this.navbarService.DownloadedFile = null;
  }
}
