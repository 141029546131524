
<div *ngIf="getUrlParamAndTriggerDownload$ | async; else downloadError">
  <div class="container">
    <app-pdf-doc-viewer [pdfSrc]="downloadedFile.blob"></app-pdf-doc-viewer>
  </div>
</div>

<ng-template #downloadError>
  <div class="app flex-row align-items-center" *ngIf="errorMessage">
    <div class="clearfix">
      <p class="text-bold" style="font-size: x-large;">{{ errorMessage }}</p>
    </div>
  </div>
</ng-template>