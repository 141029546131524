<nav class="navbar navbar-expand-md navbar-dark position-fixed-top bg-primary">
  <div class="container-fluid">
    <div class="navbar-header">
      <a class="navbar-brand" [routerLink]="['']" >
        <img class="logo" alt="ViacomCBS" src="../assets/cbs.png" />
      </a>
      <span class="fileName" *ngIf="navbarService.DownloadedFile">
        <img alt="PDF" src="../assets/icon/PDF_file_icon.svg" />
        {{navbarService.DownloadedFile.fileName}}
      </span>
    </div>
    <ul class="nav navbar-nav navbar-right">
      <li>
        <a aria-label="Download" title="Download" class="btn btn-success btn-sm float-right" (click)="navbarService.downloadFile()"
          *ngIf="navbarService.DownloadedFile">
          <svg xmlns="http://www.w3.org/2000/svg" version="1" height="16" viewBox="0 0 48 48"
            enable-background="new 0 0 48 48">
            <g fill="#fff">
              <polygon points="24,37.1 13,24 35,24" />
              <rect x="20" y="4" width="8" height="4" />
              <rect x="20" y="10" width="8" height="4" />
              <rect x="20" y="16" width="8" height="11" />
              <rect x="6" y="40" width="36" height="4" />
            </g>
          </svg>
          Download
        </a>
      </li>
    </ul>
  </div>
</nav>
<div class="container">
  <router-outlet></router-outlet>
  <app-loading-screen></app-loading-screen>
</div>