import { Injectable } from '@angular/core';
import { DownloadedFile } from '../models/downloaded-file';
import { saveAs } from 'file-saver';

@Injectable()
export class NavbarService {
  DownloadedFile: DownloadedFile = null;

  constructor() { }

  downloadFile() {
    var blob = new Blob([this.DownloadedFile.blob], {
      type: this.DownloadedFile.contentType,
    });
    saveAs(blob, this.DownloadedFile.fileName);
  }
}
