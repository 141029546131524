import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpResponse,
} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DownloadedFile } from '../models/downloaded-file';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  apiUrl = this.baseUrl + 'pdf/';
  errorList: any = new Array();;



  downloadPDF(url: string): Observable<DownloadedFile> {
    const downloadUrl = encodeURIComponent(url);
    return this.http
      .get(this.apiUrl + 'azure', { responseType: "arraybuffer", observe: "response", params: { pdfUrl: downloadUrl } })
      .pipe(
        map((httpResponse: HttpResponse<ArrayBuffer>) =>
          this.extractFileNameAndBlob(httpResponse)
        )
      );
  }

  extractFileNameAndBlob(httpResponse: HttpResponse<ArrayBuffer>): DownloadedFile {
    const headers = httpResponse.headers;
    const contentDisposition = headers.get("Content-Disposition");


    if (contentDisposition == null) {
      this.errorList.push('Server Error: Cannot retrieve file name.Either Content-Dispostion header is not available or it is not exposed using Access-Control-Expose Header.')
      return;
    }

    const fileNameItem = contentDisposition.split(";").map(item => item.trim()).find(item => item.toLowerCase().startsWith("filename="));

    if (fileNameItem == null) {
      this.errorList.push(`Server didn't send a filename`);
      return;
    }

    let fileName = fileNameItem.split("=")[1];
    if (fileName.startsWith('"'))
      fileName = fileName.substring(1, fileName.length - 1);

    const contentType = headers.get("Content-Type");
    return {
      blob: httpResponse.body,
      fileName: fileName,
      contentType: contentType,
      error: this.errorList
    };

  }

}

