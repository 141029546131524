import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingScreenComponent } from './loader/loading-screen.component';
import { LoadingScreenService } from './loader/loading-screen.service';
import { LoadingScreenInterceptor } from './loader/loading.interceptor';
import { PdfService } from './service/pdf-service';
import { PdfDocViewerComponent } from './pdf-doc-viewer/pdf-doc-viewer.component';
import { HomeComponent } from './home/home.component';
import { SupplementContractViewerComponent } from './supplement-contract-viewer/supplement-contract-viewer.component';
import { NavbarService } from './service/navbar-service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoadingScreenComponent,
    SupplementContractViewerComponent,
    PdfDocViewerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
  ],
  providers: [
    NavbarService,
    PdfService,
    LoadingScreenService,
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
